import Vue from 'vue';
import Coach from './Coach.vue';
import './service-worker-register';
import { routes } from './router/coach';
import store from './store/coach';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import GlobalAlert from './plugins/global-alert';
import VueHead from 'vue-head';
import VuetifyConfirm from 'vuetify-confirm';
import VueRouter from 'vue-router';
import { authGuard } from '@/services/auth';
import VueGtag from 'vue-gtag';
import ConfirmDangerous from './plugins/confirm-dangerous';
import pinia from './plugins/pinia';

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development'; // Shows component timings in chrome devtools
Vue.use(GlobalAlert);
Vue.use(VueHead);
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(ConfirmDangerous, { vuetify });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(authGuard(store));

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_COACH_GOOGLE_ANALYTICS_ID) {
  let includes = null;
  if (process.env.VUE_APP_VIDEO_GOOGLE_ANALYTICS_ID) {
    includes = [{ id: process.env.VUE_APP_VIDEO_GOOGLE_ANALYTICS_ID }];
  }
  Vue.use(
    VueGtag,
    {
      includes: includes,
      config: { id: process.env.VUE_APP_COACH_GOOGLE_ANALYTICS_ID },
      bootstrap: false,
    },
    router
  );
}

new Vue({
  store,
  router,
  pinia,
  i18n,
  vuetify,
  render: h => h(Coach),
}).$mount('#app');
