import { defineStore } from 'pinia';
import { AuthResponse } from '@/models/authResponse';
import { makeRequest } from '@/services//api-request';
import { MemberTag } from '@/models/memberTag';

export const useMemberTagsStore = defineStore('member-tags', {
  state: () => ({
    memberTags: [] as Array<MemberTag>,
  }),
  actions: {
    async fetchMemberTags() {
      const authResponse: AuthResponse = await makeRequest('GET', '/member-tags');
      this.memberTags = authResponse.body;
    },
    async assignMemberTag(memberUuid: string, tag: string) {
      const data = { member_uuid: memberUuid };
      await makeRequest('POST', `/member-tags/${tag}/members`, { body: JSON.stringify(data) });
    },
    async unassignMemberTag(memberUuid: string, tag: string) {
      await makeRequest('DELETE', `/member-tags/${tag}/members/${memberUuid}`);
    },
  },
});
