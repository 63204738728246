import { RegisterEntry } from '@/models/registerEntry';
import Dexie from 'dexie';
import { StoreDb } from '@/services/db/index';
import { Member, Assessments, Scores, Progress, BadgeAward, Awards } from '@/models/member';
import { GroupMember } from '@/models/groupMember';
import { Group } from '@/models/group';
import { SearchOptions } from '@/models/searchOptions';

class MembersDb extends StoreDb {
  /**
   * Loads all the groups supplied to it
   *
   * @param {Array<Group>} groups
   */
  loadGroups(groups: Array<Group>): Promise<void> {
    return this.transaction('rw', this.groups, () => {
      groups.forEach((group: Group) => {
        this.groups.put(group, group.id);
      });
    });
  }

  /**
   * Gets the groups either all of them or just the ones that match
   * the search options provided.
   *
   * @param searchOptions
   */
  async getGroups(searchOptions: SearchOptions): Promise<Array<Group>> {
    const searchStr = searchOptions.search ? searchOptions.search.toString() : '';
    if (searchStr.length > 0) {
      // Return all groups with a matching name
      const regexp = new RegExp(searchStr, 'i');
      return await this.transaction('rw', this.groups, async () => {
        return this.groups
          .filter(function(group: Group) {
            return regexp.test(group.name);
          })
          .toArray();
      });
    } else if (searchOptions.ngo_id) {
      // Return all groups matching the teacher NGO ID, or who have no NGO ID (are local).
      return await this.groups
        .filter((group: Group) => !('teacher_ngo_id' in group) || searchOptions.ngo_id === group.teacher_ngo_id)
        .toArray();
    } else {
      // All groups
      return await this.groups.toArray();
    }
  }

  async deleteGroups(userNgoId: string): Promise<Array<number>> {
    const groups = await this.groups
      .where('teacher_ngo_id')
      .equals(userNgoId)
      .toArray();
    const groupIds = groups.map((g: Group) => g.id);
    const d1 = await this.transaction('rw', this.groupMembers, () => {
      return this.groupMembers
        .where('group_id')
        .anyOf(groupIds)
        .delete();
    });
    const d2 = await this.transaction('rw', this.groups, () => {
      return this.groups
        .where('teacher_ngo_id')
        .equals(userNgoId)
        .delete();
    });
    return [d1, d2];
  }

  /**
   * Returns the group that matches the supplied id
   *
   * @param {string} groupId
   */
  async getGroupById(groupId: number): Promise<Group | undefined> {
    return await this.transaction('rw', this.groups, async () => {
      const group = await this.groups
        .where('id')
        .equals(groupId)
        .first();
      return group as Group;
    }).then((groupresult: Group) => {
      return groupresult as Group;
    });
  }

  /**
   * Returns the groups for the member with the supplied uuid.
   *
   * @param {string} uuid
   */
  async getGroupsByMemberUuid(uuid: string): Promise<Array<GroupMember>> {
    return await this.groupMembers
      .where('member_uuid')
      .equals(uuid)
      .toArray();
  }

  /**
   * Loads the group members per groupId
   *
   * @param {string} groupId
   * @param {Array<Member} members
   */
  async loadGroupMembersByGroupId(groupId: number, members: Array<Member>): Promise<void> {
    // find the group and attach its members
    await this.transaction('rw', this.groups, async () => {
      // attach the members and set total_members
      await this.groups
        .where('id')
        .equals(groupId)
        .modify({ total_members: members.length, members_loaded: true });
    });
    try {
      // load groupMember mapping table
      await this.saveGroupMembers(groupId, members);
    } catch (error) {
      console.error('Failed saving group members ' + error);
    }
  }

  /**
   *
   * @param {string} groupId
   * @param {Array<Member>} members
   */
  async saveGroupMembers(groupId: number, members: Array<Member>) {
    return Promise.all(
      members.map(async (member: Member) => {
        // also add them to the member table if they already exist it will just replace them
        member.group_id = groupId;
        await this.members.put(member, [groupId, member.uuid]);

        await this.groupMembers.put(
          {
            group_id: groupId,
            member_uuid: member.uuid,
          },
          [groupId, member.uuid]
        );
      })
    );
  }

  /**
   * Returns the number of members that are in the supplied group
   *
   * @param {string} groupId
   */
  async getGroupMemberCount(groupId: number): Promise<number> {
    return this.groupMembers
      .where('group_id')
      .equals(groupId)
      .count();
  }

  /**
   * Returns the members that are in the supplied group
   *
   * @param {string} groupId
   */
  async getMembersByGroupId(groupId: number): Promise<Array<Member> | undefined> {
    return await this.groupMembers
      .where('group_id')
      .equals(groupId)
      .toArray()
      .then(async (groupMembers: Array<GroupMember>) => {
        return await this.getMembersFromGroupMemberUuids(groupId, groupMembers);
      });
  }

  /**
   * Gets a member object based on member uuid supplied for each uuid in the array
   *
   * @param {GroupMember} groupMembers
   */
  async getMembersFromGroupMemberUuids(groupId: number, groupMembers: Array<GroupMember>): Promise<Array<Member>> {
    const membersInGroup: Array<Member> = [];
    for (const groupMember of groupMembers) {
      const member: Member | undefined = await this.getMemberByUuid(groupId, groupMember.member_uuid);
      if (member) {
        membersInGroup.push(member);
      } // no need to worry if not there its unlikely and if deleted unnecessary
    }
    return membersInGroup;
  }

  /**
   * Returns the member that matches the supplied uuid
   *
   * @param {string} uuid
   */
  async getMemberByUuid(groupId: number, uuid: string): Promise<Member | undefined> {
    return this.members
      .where({
        uuid,
        group_id: groupId,
      })
      .first();
  }

  /**
   * Marks the member register
   *
   * @param {RegisterEntry} registerEntry
   */
  async markMemberRegister(registerEntry: RegisterEntry) {
    const localGroups = await this.groups.filter(group => !('ngo_id' in group)).toArray();
    this.transaction('rw', this.members, async () => {
      await this.members
        .where('uuid')
        .equals(registerEntry.member.uuid)
        .and(
          member =>
            member.group_id === registerEntry.member.group_id || localGroups.some(group => group.id === member.group_id)
        )
        .modify({ in: registerEntry.member.in, registerDate: registerEntry.registerDate });
    })
      .catch(Dexie.ModifyError, error => {
        // ModifyError did occur
        console.error(error.failures.length + ' failed to register member');
      })
      .catch(error => {
        console.error('[StoreDb]: ' + error);
      });
  }

  async setBadges(memberUuid: string, awards: Array<BadgeAward>) {
    // This updates all members across groups
    return this.transaction('rw', this.members, async () => {
      await this.members
        .where('uuid')
        .equals(memberUuid)
        .modify({ badges: awards });
    });
  }

  async setTags(memberUuid: string, tags: Array<string>) {
    // This updates all members across groups
    return this.transaction('rw', this.members, async () => {
      await this.members
        .where('uuid')
        .equals(memberUuid)
        .modify({ tags: tags });
    });
  }

  async markForMove(memberUuid: string, groupId: number, remoteNodeId: string | null, scores: Scores) {
    // This updates all members across groups
    return this.transaction('rw', this.members, async () => {
      await this.members
        .where({
          uuid: memberUuid,
          group_id: groupId,
        })
        .modify({ move_remote_node_id: remoteNodeId, scores });
    });
  }

  /**
   * Updates the assessment for the member supplied in the AssessmentDetail object
   *
   * @param {string} memberUuid
   * @param {Scores} scores
   * @param {Progress} progress
   */
  async updateAssessment(
    groupId: number,
    memberUuid: string,
    scores: Scores,
    assessments: Assessments,
    progress: Progress | undefined,
    awards: Awards
  ) {
    this.transaction('rw', this.members, async () => {
      await this.members
        .where({
          uuid: memberUuid,
          group_id: groupId,
        })
        .modify({ scores, assessments, progress, awards });
    })
      .catch(Dexie.ModifyError, error => {
        // ModifyError did occur
        console.error(error.failures.length + ' failed to update assessment for member');
      })
      .catch(error => {
        console.error('[StoreDb]: ' + error);
      });
  }
}

export const membersDb = new MembersDb();
