import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { memberRoute } from '@/router/coach';
import { Route } from 'vue-router';
import { Member } from '@/models/member';
import { Group } from '@/models/group';
import { orderBy } from 'lodash';
import moment from 'moment';

@Component({
  name: 'GroupScope',
})
class GroupScope extends Vue {
  @Prop() groupId!: number;

  async loadGroup() {
    const groupExists = await this.$store.dispatch('nav/updateGroupNameById', this.groupId);
    if (!groupExists) {
      this.$router.push({ name: 'member-groups' });
      return;
    }
    if (!('id' in this.group)) {
      // if the group is not in the store (page refresh perhaps), then load it
      await this.$store.dispatch('groups/fetchItems');
    }
    await this.$store.dispatch('groupMembers/fetchMembersByGroupId', this.groupId);
  }

  /**
   * Returns the groups members from the store.
   *
   * @return {Array<Member>}
   */
  get groupMembers(): Array<Member> {
    // default sort order is by firstname
    return orderBy(
      this.$store.getters['groupMembers/groupMembers'],
      (member: Member) => member.firstname.toLowerCase(),
      'asc'
    );
  }

  /**
   * Returns the number of members in the group that are marked as present for the current day alongside the total
   * number of members in the group.  Formatted for display.
   *
   * @return {string}
   */
  get groupTotal(): string {
    const groupMembers = this.groupMembers;
    const totalGroupMembers = groupMembers.length;
    const groupMembersPresent = groupMembers.filter((member: Member) => member.in).length;
    return `${groupMembersPresent}/${totalGroupMembers}`;
  }

  get group(): Group {
    return this.$store.getters['groups/groupById'](this.groupId) as Group;
  }

  /**
   * Returns the current groups name via it's ID.
   *
   * @return {string}
   */
  get groupName(): string {
    const name = this.$store.getters['nav/groupName'];
    if (this.group.start_at) {
      return `${moment(this.group.start_at).format('HH:mm')} ${name}`;
    }
    return name;
  }

  get groupDate(): string {
    return (this.group.start_at ? moment(this.group.start_at) : moment()).format('YYYY-MM-DD');
  }

  get getAssessByHeading(): string {
    if (this.group.start_at && this.group.start_at.length !== 0) {
      return this.$t('groups-menu.assess-by-pupil');
    } else {
      return this.$t('groups-menu.assess-by-member');
    }
  }

  /**
   * Constructs a path for the return to the current groups menu.
   *
   * @return {Route} - NOTE incomplete route returned.
   */
  get groupLink(): Route {
    return { path: memberRoute + '/' + this.groupId } as Route;
  }
}

export { GroupScope };
