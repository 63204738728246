import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import auth from '@/store/modules/auth';
import clubs from '@/store/modules/clubs';
import schemes from '@/store/modules/schemes';
import groups from '@/store/modules/groups';
import groupMembers from '@/store/modules/groupMembers';
import labels from '@/store/modules/labels';
import planning from '@/store/modules/planning';
import lessonPlanGroup from '@/store/modules/lessonPlanGroup';
import lessonPlanGroups from '@/store/modules/lessonPlanGroups';
import lessonPlans from '@/store/modules/lessonPlans';
import { state, mutations, getters, IState } from './root';
import storeDb from '@/store/modules/storeDb';
import { db } from '@/services/db/index';
import nav from '@/store/modules/nav';
import { useMemberTagsStore } from '@/stores/member-tags';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions: {
    resetState: async (context: ActionContext<IState, IState>) => {
      useMemberTagsStore().$reset();
      return Promise.all([
        context.dispatch('auth/resetState'),
        context.dispatch('clubs/resetState'),
        context.dispatch('groupMembers/resetState'),
        context.dispatch('groups/resetState'),
        context.dispatch('labels/resetState'),
        context.dispatch('lessonPlans/resetState'),
        context.dispatch('memberTags/resetState'),
        context.dispatch('nav/resetState'),
        context.dispatch('planning/resetState'),
        context.dispatch('lessonPlanGroups/resetState'),
        context.dispatch('lessonPlanGroup/resetState'),
        context.dispatch('schemes/resetState'),
        db.clearTables(),
      ]);
    },
  },
  modules: {
    auth,
    clubs,
    groupMembers,
    groups,
    labels,
    lessonPlans,
    nav,
    planning,
    lessonPlanGroup,
    lessonPlanGroups,
    schemes,
    storeDb,
  },
  getters,
});
