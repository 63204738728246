import { Group } from '@/models/group';
import { Member } from '@/models/member';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { ResponseSummary } from '@/models/responseSummary';
import { getBaseUrl, authFetch } from '@/services/auth';
import { AuthResponse } from '@/models/authResponse';
import { SearchOptions } from '@/models/searchOptions';
import { membersDb } from '@/services/db/members';
import { makeRequest } from '@/services/api-request';
import moment from 'moment';
import { GroupState } from '@/models/groupState';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import asyncPool from 'tiny-async-pool';

/**
 * List state values for the groups related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - GroupState
 */
const getDefaultState = (): GroupState => ({
  items: [] as Array<Group>,
  selected: [], // Selected groups.
  search: '',
  errors: [] as Array<ErrorIdentity>,
  sessionDate: moment().format('YYYY-MM-DD'),
  teacherNgoIds: [] as Array<string>,
  isLoading: false,
  openGroupSessionsModal: 0,
});

const state = getDefaultState();

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: GroupState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateItems: (moduleState: GroupState, data: Array<Group>) => {
    moduleState.items = data || [];
  },
  updateTeacherNgoIds: (moduleState: GroupState, teacherNgoIds: Array<string>) => {
    moduleState.teacherNgoIds = teacherNgoIds;
  },

  groupLoadingComplete: (moduleState: GroupState, groupId: number) => {
    const group = (moduleState.items as Array<Group>).find((g: Group) => g.id === groupId);
    if (group) {
      group.members_loaded = true;
    }
  },
  clearSelected: (moduleState: GroupState) => {
    moduleState.selected = [];
  },
  updateSelected: (moduleState: GroupState, selectedGroups: Array<Group>) => {
    moduleState.selected = selectedGroups;
  },

  updateDate: (moduleState: GroupState, date: string) => {
    moduleState.sessionDate = date;
  },

  updateSearch: (moduleState: GroupState, searchStr: string) => {
    searchStr = searchStr.trim();
    // remove invalid user input
    searchStr = searchStr.replace(/[|&;$%@"<>()+,]/g, '');
    moduleState.search = searchStr.toLowerCase();
  },
  updateErrors: (moduleState: GroupState, errorDetail: ErrorIdentity) => {
    if (moduleState.errors) {
      moduleState.errors.push(errorDetail);
    }
  },
  updateLoading: (moduleState: GroupState, isLoading: boolean) => {
    moduleState.isLoading = isLoading;
  },
  clearErrors: (moduleState: GroupState) => {
    moduleState.errors = [];
  },
  updateOpenGroupSessionsModal: (moduleState: GroupState, openGroupSessionsModal: number) => {
    moduleState.openGroupSessionsModal = openGroupSessionsModal;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<GroupState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * Fetches groups from api with current page, sort and sort order included.
   *
   * @param {ActionContext} context
   * @return void
   */
  fetchGroups: async (context: ActionContext<GroupState, RootState>) => {
    const queryParams: Record<string, string> = {
      search: '',
      sort: 'aname',
      with_sessions: '1',
    };
    if (context.state.search && context.state.search.length > 0) {
      queryParams.search = context.state.search;
    }
    const url = `/groups?${new URLSearchParams(queryParams)}`;
    try {
      context.commit('updateLoading', true);
      const authResponse: AuthResponse = await makeRequest('GET', url);
      const totalRecords = authResponse.response.headers.get('X-Total-Count');
      if (totalRecords) {
        context.commit('updateItems', authResponse.body as Array<Group>);
      } else {
        context.dispatch('resetToEmpty');
      }
    } catch (error) {
      context.commit('updateErrors', error);
      context.dispatch('resetToEmpty');
      console.error(error);
    } finally {
      context.commit('updateLoading', false);
    }
  },

  resetToEmpty: (context: ActionContext<GroupState, RootState>) => {
    context.commit('updateItems', []);
    context.commit('updateLoading', false);
  },

  updateGroupSessionsModal(context: ActionContext<GroupState, RootState>, openGroupSessionsModal: number) {
    context.commit('updateOpenGroupSessionsModal', openGroupSessionsModal);
  },

  /**
   * Send a request to mark the given group as inactive.
   *
   * @param {ActionContext} context
   * @param {string} groupId
   * @return void
   */
  changeGroupStatus: async (
    context: ActionContext<GroupState, RootState>,
    data: { groupId: number; status: 1 | 0 }
  ) => {
    const url = `/groups/${data.groupId}`;
    await makeRequest('PATCH', url, { body: JSON.stringify({ status: data.status }) });
    await context.dispatch('fetchGroups');
  },

  /**
   * For a given set of group IDs and set of members will add all given members to each given group.
   * Uses the bulk endpoint for each groups call.
   *
   * @param {ActionContext} context
   * @param {object} data = `{members: Array<Member>, groupIds: Array<string>>}`.
   * @return void
   */
  bulkChangeGroupMembers: async (
    context: ActionContext<GroupState, RootState>,
    data: { members: Array<Member>; groupIds: Array<string>; action: 'assign' | 'unassign' }
  ) => {
    const members = data.members.map(member => member.uuid);

    return Promise.all(
      data.groupIds.map(async (group: string) => {
        const url = getBaseUrl() + '/groups/' + group + '/members/bulk';

        // not using makeRequest since we want to handle response.ok ourselves
        const authResponse = await authFetch(url, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            action: data.action,
            member_uuids: members,
          }),
        });

        return {
          status: authResponse.response.status,
          ok: authResponse.response.ok,
          json: authResponse.body,
        } as ResponseSummary;
      })
    );
  },

  addGroup: async (context: ActionContext<GroupState, RootState>, newGroupName: string) => {
    const url = getBaseUrl() + '/groups';

    const isOrg = context.rootGetters['auth/isOrganisationPortal'];
    const schemeUuid = isOrg ? null : context.rootGetters['schemes/defaultSchemeSlug'];
    return makeRequest('POST', url, {
      body: JSON.stringify({ name: newGroupName, scheme_uuid: schemeUuid, status: 0 }),
    });
  },

  deleteGroups: async (context: ActionContext<GroupState, RootState>, data: Array<Group>) => {
    return Promise.all(
      data.map(async (group: Group) => {
        const url = getBaseUrl() + '/groups/' + group.id;

        // not using makeRequest since we want to handle response.ok ourselves
        const authResponse = await authFetch(url, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'DELETE',
        });

        return {
          status: authResponse.response.status,
          ok: authResponse.response.ok,
          json: authResponse.response.status === 204 ? authResponse.body : null,
        } as ResponseSummary;
      })
    );
  },

  syncWithStoreSessionDate: async (context: ActionContext<GroupState, RootState>) => {
    const date =
      (await context.dispatch('storeDb/getSetting', 'sessionDate', { root: true })) || moment().format('YYYY-MM-DD');
    context.commit('updateDate', date);
  },

  updateDate: async (context: ActionContext<GroupState, RootState>, date: string) => {
    await context.dispatch('storeDb/setSetting', { setting: 'sessionDate', value: date }, { root: true });
    context.commit('updateDate', date);
  },

  changeDate: async (context: ActionContext<GroupState, RootState>, { date = '', force = false } = {}) => {
    if (date !== context.state.sessionDate || force) {
      await context.dispatch('updateDate', date);
      // reload the group items for the current date
      return context.dispatch('fetchItems', { forceSync: true });
    }
  },

  /**
   * Fetches the groups and saves them in the vuex store state
   *
   * @param {ActionContext<GroupState, RootState>} context
   */
  fetchItems: async (
    context: ActionContext<GroupState, RootState>,
    options = {
      forceSync: false,
      allGroups: false,
      fetchOnly: false,
      progress: (_n: number, _t: number) => {
        // do nothing
      },
    }
  ) => {
    // build the searchObject
    const searchOptions = {
      search: context.state.search,
      sort: 'name',
      sortDirection: 'a',
    };
    const t0 = performance.now();
    if (options.forceSync) {
      await context.dispatch('groupMembers/resetState', '', { root: true });
    }
    try {
      if (context.rootGetters['storeDb/isLoadingMembers'] || context.rootGetters['storeDb/isLoadingGroups']) {
        return; // already loading
      }

      // check if we need to sync, and clear tables if so.
      const allGroupsImported =
        (await context.dispatch('storeDb/getSetting', 'allGroupsImported', { root: true })) || false;
      const syncOptions = Object.assign(
        {
          allGroupsImported,
          userNgoId: context.rootGetters['auth/loggedInUserNgoId'],
        },
        options
      );
      const doFetch = await context.dispatch('storeDb/startMemberSync', syncOptions, { root: true });
      context.commit('updateLoading', true);

      // fetch data into the dexie store if we need to sync
      const allGroups = doFetch ? await context.dispatch('storeDb/loadGroupsIntoStoreDb', options, { root: true }) : [];

      const db = membersDb;

      const checkMembersLoaded = async (g: Group) => {
        if (doFetch) {
          return false; // refetch
        }

        // If we do not have all the members in the db, then the group was not fully loaded
        const cnt = await db.getGroupMemberCount(g.id);
        return cnt === g.total_members;
      };

      // then load it into vuex
      if (!options.fetchOnly) {
        const groups: Array<Group> = await context.dispatch('fetchItemsFromStoreDb', searchOptions);

        const items = await Promise.all(
          groups.map(async g => Object.assign(g, { members_loaded: await checkMembersLoaded(g) }))
        );

        // Specify if the group members are loaded or not
        context.commit('updateItems', items);
      }
      context.commit('updateLoading', false);

      if (options.progress) {
        options.progress(0, allGroups.length);
      }

      // now load active schemes based on the fetched groups
      await context.dispatch('schemes/loadActiveSchemes', '', { root: true });

      let done = 0;
      const loadGroupMembers = async (group: Group) => {
        if (await context.dispatch('storeDb/processLoadingMembers', group, { root: true })) {
          done += 1;
          if (options.progress) {
            options.progress(done, allGroups.length);
          }
          return context.commit('groupLoadingComplete', group.id);
        }
      };

      if (doFetch) {
        // get the group members, 5 groups at a time.
        for await (const _r of asyncPool(5, allGroups, loadGroupMembers)) {
          // chunk done
        }
      }

      await context.dispatch('storeDb/endMemberSync', null, { root: true });

      // If we have done an import all classes then update for switch user.
      if (options.allGroups && options.forceSync) {
        const teacherNgoIds = allGroups
          .map((g: Group) => ('teacher_ngo_id' in g ? g.teacher_ngo_id : null))
          .filter((ngoId: string | null) => !_isEmpty(ngoId)) as Array<string>;
        context.commit('updateTeacherNgoIds', _uniq(teacherNgoIds));
        await context.dispatch('storeDb/setSetting', { setting: 'allGroupsImported', value: true }, { root: true });
      }

      const t = ((performance.now() - t0) / 1000).toFixed(1);
      console.log(`Synced groups and members in ${t}s`);

      return allGroups.length;
    } catch (error) {
      console.error('Error fetching groups from StoreDb: ', error);
    }

    // NOTE: If we want to further speed this up we might look at picking apart the Members store. Instead of having
    // a copy of each member PER GROUP, we could have a single copy of each member, with their progress keyed by scheme,
    // Currently the backend respects group level restrictions when calculating progress, which means that progress is
    // in fact group-specific, but it might be possible to change this to work more like the coursepro client, get the
    // raw scheme progress then filter it per-group if necessary.
    // We could then have a single endpoint to fetch 'all members, with a group today for this user' and fetch the actual
    // list of members (just an array of member UUIDs) along with the initial list of groups.
  },

  /**
   * Fetches the groups from Store Db
   *
   * @param {ActionContext<GroupState, RootState>} context
   * @param searchOptions
   */
  fetchItemsFromStoreDb: async (context: ActionContext<GroupState, RootState>, searchOptions: SearchOptions) => {
    const loggedInUserNgoId = context.rootGetters['auth/loggedInUserNgoId'];
    if (loggedInUserNgoId) {
      searchOptions.ngo_id = loggedInUserNgoId;
    }
    return await membersDb.getGroups(searchOptions);
  },

  /**
   * Update current selected group's name
   * Group name could be emtpy string so doesn't break the UI
   *
   * @param {ActionContext<GroupState, RootState>} context
   * @param {string} groupId
   * @return {string} groupName
   */
  updateGroupName: async (context: ActionContext<GroupState, RootState>, groupId: number) => {
    await context.dispatch('getGroupNameFromStoreDb', groupId.toString()).then(groupName => {
      context.commit('nav/updateGroupName', groupName);
    });
  },

  /**
   * Fetchs groups name from the DbStore
   *
   * @param {ActionContext<GroupState, RootState>} context
   * @param groupId
   * @return {string} groupName
   */
  getGroupNameFromStoreDb: async (context: ActionContext<GroupState, RootState>, groupId: number) => {
    try {
      return await membersDb.getGroupById(groupId).then(group => {
        let groupName = '';
        if (group) {
          groupName = group.name;
        } else {
          groupName = ''; // don't break the UI
        }
        return groupName;
      });
    } catch (err) {
      console.error('[StoreDb] - Could not get group name ', err);
    }
  },

  /**
   * Update the group name in the API
   *
   * @param {ActionContext} context
   * @param {string} groupId
   * @return void
   */
  updateGroupNameInApi: async (
    context: ActionContext<GroupState, RootState>,
    data: { groupId: number; name: string }
  ) => {
    const url = `/groups/${data.groupId}`;
    await makeRequest('PATCH', url, { body: JSON.stringify({ name: data.name }) });
    context.dispatch('fetchGroups');
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  groups: (moduleState: GroupState) => moduleState.items,
  schemeSlugs: (moduleState: GroupState) => [...new Set((moduleState.items as Array<Group>).map(g => g.scheme_slug))],
  remoteAndNonEmptyLocalGroups: (moduleState: GroupState) => {
    const currGroups = moduleState.items as Array<Group>;
    return currGroups.filter((g: Group) => ('ngo_id' in g && g.ngo_id !== null) || g.total_members > 0);
  },
  isLoading: (moduleState: GroupState) => moduleState.isLoading,
  openGroupSessionsModal: (moduleState: GroupState) => moduleState.openGroupSessionsModal,
  selectedGroups: (moduleState: GroupState) => moduleState.selected,
  groupById: (moduleState: GroupState) => (groupId: number) => {
    const currGroups = moduleState.items as Array<Group>;
    return currGroups.find(({ id }) => id === groupId) || [];
  },
  search: (moduleState: GroupState) => moduleState.search,
  errors: (moduleState: GroupState) => moduleState.errors,
  sessionDate: (moduleState: GroupState) => moduleState.sessionDate,
  sessionPlanUuids: (moduleState: GroupState) => {
    const groups = moduleState.items as Array<Group>;
    const sessionPlanUuids = groups.map((g: Group) => g.session_plan_uuid || null);
    return _uniq(sessionPlanUuids.filter((uuid: string | null) => uuid !== null));
  },
  isToday: (moduleState: GroupState) => () => moduleState.sessionDate === moment().format('YYYY-MM-DD'),
  canImportAllGroups: (moduleState: GroupState, getters: any, rootState: any, rootGetters: any) => () => {
    if (!getters.isToday()) {
      return false; // can only download the current day
    }

    if (rootGetters['auth/isOrigUser']()) {
      // The SWITCH_USER feature corresponds to the view_all_classes permission in coursepro.
      // If we have logged in as an admin, we allow downloading classes even if this user has no groups with the permission.
      return rootGetters['auth/isFeatureEnabled']('SWITCH_USER');
    } else {
      // If we have switched to another user, we check the group permissions.
      const client = rootGetters['auth/clientDetails'] || {};
      return (
        client.auth_provider === 'coursepro' &&
        getters.remoteAndNonEmptyLocalGroups.some((r: Group) => r.can_view_all_classes)
      );
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
